import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { SubmitModal } from "components/SubmitModal";
import { Tooltip } from "components/Tooltip";
import { format } from "date-fns";
import {
  BooleanEnum,
  GetCaseQuery,
  GetProviderOrderQuery,
  OrderAfterReceptionEnum,
  ProjectTypeEnum,
  PublishCachedOrderDocument,
} from "graphql/__generated__/graphql";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { booleanToYesNo, toDecimalMultiplication, toEuro } from "utils/format";

import { useTrackEvent } from "libs/tracking";
import { keyBy, sum } from "lodash-es";
import { RiCloseFill } from "react-icons/ri";
import { useMutation } from "urql";
import { AfterReceptionDecisionLabel, ProjectMarket } from "../OrderPvModal/form";
import { PdfView } from "../PdfView";
import { isButtonEnabledFn } from "../utils";
import { useTranslation } from "react-i18next";

export const OrderValidationModal: React.FC<{
  order: GetProviderOrderQuery["order"];
  caseResult: GetCaseQuery["case"];
}> = ({ order, caseResult }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const { trackEvent } = useTrackEvent();

  const [{ fetching: isLoading }, publishCachedOrder] = useMutation(
    PublishCachedOrderDocument,
  );

  const toast = useToast();

  const providerOrderId = order?.id;

  const { reference, project, clientOrder, name } = order;

  const clientOrderReferenceToUGAP = clientOrder?.clientOrderReferenceToUGAP;

  const singleCase = caseResult;
  const caseId = singleCase.id;

  const { pv, bill, cra } = singleCase || {};

  const siret = order?.account?.siret;
  const isPvNotManagedByPartner = order?.project?.isPvNotManagedByPartner;
  const isCRAManaged = order?.project?.isCRAManaged;

  const [tabIndex, setTabIndex] = useState(0);

  const phases = order?.phases;
  const isForfait = project?.type === ProjectTypeEnum.Forfait;

  const originalRepartitionByPhaseId = keyBy(
    bill?.originalRepartition,
    (b) => b.phaseId,
  );

  const billableCost = isForfait
    ? sum(
      phases.map(
        (p) =>
          p.costBillable + (originalRepartitionByPhaseId[p.id]?.cost || 0),
      ),
    )
    : sum(
      phases.map((p) =>
        toDecimalMultiplication(
          p.timeBillable + (originalRepartitionByPhaseId[p.id]?.cost || 0),
          p.dailyRate,
        ),
      ),
    );

  const billCost = bill?.cost || 0;

  const [isInvalidCost, setIsInvalidCost] = useState(false);

  useEffect(() => {
    if (billCost > billableCost + 1 || billCost < 0) {
      setIsInvalidCost(true);
    }
  }, [billCost, billableCost, setIsInvalidCost]);

  const isButtonEnabled = isButtonEnabledFn(isPvNotManagedByPartner, bill, pv);

  const onSubmit = async () => {
    const { error } = await publishCachedOrder({
      providerOrderId,
      caseId,
    });

    if (!error) {
      toast({
        title: t(
          "order_validation_modal_title",
          "Transmission du dossier de facturation",
        ),
        description: t(
          "order_validation_modal_description_success",
          "Votre dossier de facturation a été transmis à un ADV pour instruction",
        ),
        status: "success",
        isClosable: true,
      });
      await trackEvent("Submit", "Submit case", {
        providerOrderId,
        caseId,
        projectType: project.type,
      });

      navigate("../..");
    }
  };

  const onCloseModal = () => {
    navigate(`..`);
  };

  return (
    <>
      <Modal
        isOpen={true}
        onClose={onCloseModal}
        size="full"
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent p={8}>
          <ModalBody>
            <Grid templateColumns="repeat(12, 1fr)">
              <GridItem
                colSpan={12}
                borderBottom={"1px solid #DCDCDC"}
                mb={20}
                bg={"white"}
                h="80px"
                zIndex={"10"}
                pos={"sticky"}
                top={0}
              >
                <Flex
                  justify={"space-between"}
                  align="center"
                  mb={8}
                  h={"100%"}
                >
                  <Flex>
                    <Icon
                      as={RiCloseFill}
                      color="grey"
                      fontSize={30}
                      mr={8}
                      cursor="pointer"
                      onClick={onCloseModal}
                    />
                    <Heading color="neutral.text.black" size={"xl"}>
                      {t(
                        "order_validation_modal_title_validation",
                        "Vérification avant transmission de la commande n° ",
                      )}
                      {name}
                    </Heading>
                  </Flex>

                  <Button
                    onClick={onOpen}
                    isDisabled={!isButtonEnabled || isInvalidCost}
                  >
                    {t(
                      "order_validation_modal_submit_billing_folder",
                      "Soumettre mon dossier",
                    )}
                  </Button>
                </Flex>
              </GridItem>
              <GridItem colSpan={12}>
                <Stack justify={"center"}>
                  <Text
                    fontSize="md"
                    fontWeight="normal"
                    textAlign={"center"}
                    pb={12}
                  >
                    {t(
                      "order_validation_modal_description_validation",
                      "Merci de vérifier tous les champs. Une fois validé, votre dossier sera transmis à un ADV pour validation.",
                    )}
                  </Text>
                  {isInvalidCost && (
                    <Center pb={12}>
                      <Alert status="error" maxWidth={500}>
                        <AlertIcon />
                        <Box flex="1">
                          <AlertTitle>
                            {t(
                              "order_validation_modal_invalid_cost_title",
                              "Montant invalide",
                            )}
                          </AlertTitle>
                          <AlertDescription display="block">
                            <Text>
                              {t(
                                "order_validation_modal_invalid_cost_description",
                                "Il y a un problème avec ce montant.",
                              )}
                            </Text>
                          </AlertDescription>
                        </Box>
                      </Alert>
                    </Center>
                  )}
                </Stack>
              </GridItem>
              {!isCRAManaged && (
                <GridItem
                  colSpan={4}
                  bg={tabIndex === 0 ? "neutral.grey.50" : undefined}
                  border="1px solid"
                  borderColor={
                    tabIndex === 0 ? "neutral.grey.300" : "transparent"
                  }
                  py={5}
                  px={10}
                  borderRadius="lg"
                  mt="45px"
                >
                  <Heading size={"xl"} mb={8}>
                    {t("order_validation_modal_pv_metadata", "Résumé PV")}
                  </Heading>

                  {isPvNotManagedByPartner ? (
                    pv ? (
                      <Text>
                        {t(
                          "order_validation_modal_pv_retrieved_by_ia",
                          "PV récupéré par l'IA Inop's",
                        )}
                      </Text>
                    ) : (
                      <Tooltip
                        label={t(
                          "order_validation_modal_pv_retrieved_by_ia_tooltip",
                          "NB : votre facture ne pourra être réglée qu'à la condition que nous arrivions à récupérer un PV signé auprès du client.",
                        )}
                      >
                        {t(
                          "order_validation_modal_pv_retrieved_by_ia",
                          "Le PV doit encore être récupéré par l'IA Inop's",
                        )}
                      </Tooltip>
                    )
                  ) : (
                    <>
                      <FormControl size="sm" my={3}>
                        <FormLabel>
                          {t(
                            "order_validation_modal_pv_metadata_chrono",
                            "Numéro d’affaire",
                          )}
                          <Tooltip
                            label={t(
                              "order_validation_modal_pv_metadata_chrono_tooltip",
                              "Cadre réservé au titulaire / Champ N° Chrono",
                            )}
                          />
                        </FormLabel>
                        <Text fontSize="sm" fontWeight="normal">
                          {project?.reference}
                        </Text>
                      </FormControl>
                      {project?.market !== ProjectMarket.CANUT && (
                        <FormControl size="sm" my={3}>
                          <FormLabel>
                            {t(
                              "order_validation_modal_pv_metadata_ugap_order",
                              "Numéro de commande UGAP (ARC)",
                            )}
                            <Tooltip
                              label={t(
                                "order_validation_modal_pv_metadata_ugap_order_tooltip",
                                "Cadre réservé au titulaire / N° commande UGAP (ARC)",
                              )}
                            />
                          </FormLabel>
                          <Text fontSize="sm" fontWeight="normal">
                            {clientOrderReferenceToUGAP}
                          </Text>
                        </FormControl>
                      )}
                      <FormControl size="sm" my={3}>
                        <FormLabel>
                          Numéro de commande d'achat {project?.market !== ProjectMarket.CANUT ? "(OS)" : ""}
                          <Tooltip
                            label={`Cadre réservé au titulaire / N° commande d’achat ${project?.market !== ProjectMarket.CANUT ? "(OS)" : ""}`}
                          />
                        </FormLabel>
                        <Text fontSize="sm" fontWeight="normal">
                          {reference}
                        </Text>
                      </FormControl>
                      <FormControl size="sm" my={3}>
                        <FormLabel htmlFor="deliveryDate">
                          {t(
                            "order_validation_modal_pv_metadata_delivery_date",
                            "Date de réception des prestations",
                          )}
                          <Tooltip
                            label={t(
                              "order_validation_modal_pv_metadata_delivery_date_tooltip",
                              "Cadre réservé au bénéficiaire / Date de réception des prestations",
                            )}
                          />
                        </FormLabel>
                        <Text fontSize="sm" fontWeight="normal">
                          {pv?.deliveryDate &&
                            format(pv?.deliveryDate, "dd/MM/yyyy")}
                        </Text>
                      </FormControl>
                      <FormControl size="sm" my={3}>
                        <FormLabel htmlFor="deliveryDelay">
                          {t(
                            "order_validation_modal_pv_metadata_delivery_delay",
                            "Les prestations ont bien été exécutées dans les délais",
                          )}
                          <Tooltip
                            label={t(
                              "order_validation_modal_pv_metadata_delivery_delay_tooltip",
                              "Cadre réservé au bénéficiaire / Première coche",
                            )}
                          />
                        </FormLabel>
                        {booleanToYesNo(pv?.deliveryDelay)}

                        {pv?.deliveryDelay === BooleanEnum.False && (
                          <Text
                            color="semantic.warning.main"
                            fontSize="sm"
                            fontWeight="normal"
                            mt={2}
                          >
                            {t(
                              "order_validation_modal_pv_delay_warning",
                              "Attention, le PV que vous vous apprêtez à nous transmettre fait état d’un retard projet de votre fait. Ceci légitimerait de facto toute procédure de déclenchement de pénalités par le bénéficiaire sur cet ordre de service.",
                            )}
                          </Text>
                        )}
                      </FormControl>
                      {pv?.deliveryDelay === BooleanEnum.False && (
                        <FormControl size="sm" my={3}>
                          <FormLabel htmlFor="reportClient">
                            {t(
                              "order_validation_modal_pv_report_client",
                              "Le report a été demandé par le client",
                            )}
                            <Tooltip
                              label={t(
                                "order_validation_modal_pv_report_client_tooltip",
                                "Cadre réservé au bénéficiaire / Seconde coche",
                              )}
                            />
                          </FormLabel>
                          {booleanToYesNo(pv?.reportClient)}

                          {pv?.reportClient === BooleanEnum.False && (
                            <Text
                              color="semantic.warning.main"
                              fontSize="sm"
                              fontWeight="normal"
                              mt={2}
                            >
                              {t(
                                "order_validation_modal_pv_delay_warning",
                                "Attention, le PV que vous vous apprêtez à nous transmettre fait état d’un retard projet de votre fait. Ceci légitimerait de facto toute procédure de déclenchement de pénalités par le bénéficiaire sur cet ordre de service.",
                              )}
                            </Text>
                          )}
                        </FormControl>
                      )}
                      <FormControl size="sm" my={3}>
                        <FormLabel htmlFor="afterReceptionDecision">
                          {t(
                            "order_validation_modal_pv_after_reception_decision",
                            "Décision après réception",
                          )}
                          <Tooltip
                            label={t(
                              "order_validation_modal_pv_after_reception_decision_tooltip",
                              "Cadre réservé au bénéficiaire / Troisième coche",
                            )}
                          />
                        </FormLabel>
                        <Text size="sm">
                          {AfterReceptionDecisionLabel.find(
                            (p) => p.value === pv?.afterReceptionDecision,
                          )?.label ?? ""}
                        </Text>
                        {pv?.afterReceptionDecision &&
                          [
                            OrderAfterReceptionEnum.WithWarning,
                            OrderAfterReceptionEnum.Ajourned,
                            OrderAfterReceptionEnum.Rejected,
                          ].includes(pv?.afterReceptionDecision) && (
                            <Text
                              color="semantic.warning.main"
                              fontSize="sm"
                              fontWeight="normal"
                              mt={2}
                            >
                              {t(
                                "order_validation_modal_pv_delay_warning",
                                "Attention, le PV que vous vous apprêtez à nous transmettre fait état d’un retard projet de votre fait. Ceci légitimerait de facto toute procédure de déclenchement de pénalités par le bénéficiaire sur cet ordre de service.",
                              )}
                              {t(
                                "order_validation_modal_pv_delay_warning_additional",
                                "En effet, s’il persiste des réserves quant à la prestation, vous devez les prendre en compte puis faire signer un PV sans réserve au bénéficiaire pour procéder à la facturation. Nous vous invitons donc à réduire l’avancement de la phase pour prendre en compte les reliquats sur le projet.",
                              )}
                            </Text>
                          )}
                      </FormControl>
                      <FormControl size="sm" my={3}>
                        <FormLabel htmlFor="cachet">
                          {t(
                            "order_validation_modal_pv_metadata_cachet",
                            "Le cachet du bénéficiaire",
                          )}
                          <Tooltip
                            label={t(
                              "order_validation_modal_pv_metadata_cachet_tooltip",
                              "Cadre inférieur",
                            )}
                          />
                        </FormLabel>
                        {booleanToYesNo(pv?.cachet)}

                        {pv?.cachet === BooleanEnum.False && (
                          <Text
                            color="semantic.warning.main"
                            fontSize="sm"
                            fontWeight="normal"
                            mt={2}
                          >
                            {t(
                              "order_validation_modal_pv_cachet_warning",
                              "Attention, le PV que vous vous apprêtez à nous transmettre ne respecte pas les règles de gestion du marché et risque d'être rejeté. En effet, le cachet doit être apparent dans la zone réservé au bénéficiaire pour que le PV soit accepté par l’UGAP.",
                            )}
                          </Text>
                        )}
                      </FormControl>
                      <FormControl size="sm" my={3}>
                        <FormLabel htmlFor="signature">
                          {t(
                            "order_validation_modal_pv_signature",
                            "La signature du bénéficiaire",
                          )}
                          <Tooltip
                            label={t(
                              "order_validation_modal_pv_signature_tooltip",
                              "Cadre inférieur",
                            )}
                          />
                        </FormLabel>

                        {booleanToYesNo(pv?.signature)}

                        {pv?.signature === BooleanEnum.False && (
                          <Text
                            color="semantic.warning.main"
                            fontSize="sm"
                            fontWeight="normal"
                            mt={2}
                          >
                            {t(
                              "order_validation_modal_pv_signature_warning",
                              "Attention, le PV que vous vous apprêtez à nous transmettre ne respecte pas les règles de gestion du marché et risque d'être rejeté : en effet, la signature doit être apparente dans la zone réservé au bénéficiaire pour que le PV soit accepté par l’UGAP.",
                            )}
                          </Text>
                        )}
                      </FormControl>
                      <FormControl size="sm" my={3}>
                        <FormLabel htmlFor="nameQuality">
                          {t(
                            "order_validation_modal_pv_name_quality",
                            "Nom et qualité du bénéficiaire",
                          )}
                          <Tooltip
                            label={t(
                              "order_validation_modal_pv_name_quality_tooltip",
                              "Cadre inférieur",
                            )}
                          />
                        </FormLabel>
                        {booleanToYesNo(pv?.nameQuality)}

                        {pv?.nameQuality === BooleanEnum.False && (
                          <Text
                            color="semantic.warning.main"
                            fontSize="sm"
                            fontWeight="normal"
                            mt={2}
                          >
                            {t(
                              "order_validation_modal_pv_name_quality_warning",
                              "Attention, le PV que vous vous apprêtez à nous transmettre ne respecte pas les règles de gestion du marché et risque d'être rejeté : en effet, le nom et la qualité du bénéficiaire doit être apparent dans la zone réservé au bénéficiaire pour que le PV soit accepté par l’UGAP.",
                            )}
                          </Text>
                        )}
                      </FormControl>
                      <FormControl size="sm">
                        <FormLabel htmlFor="comment">
                          {t(
                            "order_validation_modal_pv_comment",
                            "Commentaire",
                          )}
                        </FormLabel>
                        <Text size="sm">{pv?.partnerComment}</Text>
                      </FormControl>
                    </>
                  )}
                </GridItem>
              )}
              {isCRAManaged && (
                <GridItem
                  colSpan={4}
                  bg={tabIndex === 1 ? "neutral.grey.50" : undefined}
                  border="1px solid"
                  borderColor={
                    tabIndex === 1 ? "neutral.grey.300" : "transparent"
                  }
                  py={5}
                  px={10}
                  borderRadius="lg"
                  mt="45px"
                >
                  <Heading size={"xl"} mb={8}>
                    {t(
                      "order_validation_modal_cra_metadata_title",
                      "Résumé CRA",
                    )}
                  </Heading>

                  <FormControl size="sm" my={3}>
                    <FormLabel>
                      {t(
                        "order_validation_modal_cra_metadata_reference",
                        "Numéro d’affaire",
                      )}
                    </FormLabel>
                    <Text fontSize="sm" fontWeight="normal">
                      {project?.reference}
                    </Text>
                  </FormControl>

                  <FormControl size="sm" my={3}>
                    <FormLabel htmlFor="deliveryDate">
                      {t(
                        "order_validation_modal_cra_metadata_delivery_date",
                        "Date de fin de prestation",
                      )}
                    </FormLabel>
                    <Text fontSize="sm" fontWeight="normal">
                      {cra?.deliveryDate &&
                        format(cra?.deliveryDate, "dd/MM/yyyy")}
                    </Text>
                  </FormControl>

                  <FormControl size="sm" my={3}>
                    <FormLabel htmlFor="signature">La signature</FormLabel>

                    {booleanToYesNo(cra?.signature)}

                    {cra?.signature === BooleanEnum.False && (
                      <Text
                        color="semantic.warning.main"
                        fontSize="sm"
                        fontWeight="normal"
                        mt={2}
                      >
                        {t(
                          "order_validation_modal_cra_signature_warning",
                          "Attention, le CRA que vous vous apprêtez à nous transmettre ne respecte pas les règles de gestion du marché et risque d'être rejeté : en effet, la signature doit être apparente.",
                        )}
                      </Text>
                    )}
                  </FormControl>

                  <FormControl size="sm">
                    <FormLabel htmlFor="comment">
                      {t("order_validation_modal_cra_comment", "Commentaire")}
                    </FormLabel>
                    <Text size="sm">{cra?.partnerComment}</Text>
                  </FormControl>
                </GridItem>
              )}
              <GridItem colSpan={4}>
                <Tabs
                  variant="soft-rounded"
                  size="sm"
                  align="center"
                  isLazy={true}
                  onChange={(index) => {
                    setTabIndex(index);
                  }}
                  defaultIndex={0}
                >
                  <TabList>
                    {!isPvNotManagedByPartner && (
                      <Tab>{t("order_validation_modal_pv", "Votre PV")}</Tab>
                    )}
                    {isCRAManaged && (
                      <Tab>{t("order_validation_modal_cra", "Votre CRA")}</Tab>
                    )}
                    <Tab>
                      {t("order_validation_modal_bill", "Votre Facture")}
                    </Tab>
                  </TabList>

                  <TabPanels>
                    {!isPvNotManagedByPartner && (
                      <TabPanel>
                        <PdfView
                          path={pv?.path}
                          base64={`data:application/pdf;base64,${pv?.attachment?.b64}`}
                        />
                      </TabPanel>
                    )}
                    {isCRAManaged && (
                      <TabPanel>
                        <PdfView
                          path={cra?.path}
                          base64={`data:application/pdf;base64,${cra?.attachment?.b64}`}
                        />
                      </TabPanel>
                    )}
                    <TabPanel>
                      <PdfView
                        path={bill?.path}
                        base64={`data:application/pdf;base64,${bill?.attachment?.b64}`}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </GridItem>
              <GridItem
                colSpan={4}
                py={5}
                px={10}
                borderRadius="lg"
                border="1px solid"
                mt="45px"
                borderColor={
                  tabIndex === (isCRAManaged ? 2 : 1)
                    ? "neutral.grey.300"
                    : "transparent"
                }
                bg={
                  tabIndex === (isCRAManaged ? 2 : 1)
                    ? "neutral.grey.50"
                    : "white"
                }
              >
                <Heading size={"xl"} mb={8}>
                  {t(
                    "order_validation_modal_bill_metadata_title",
                    "Résumé Facture",
                  )}
                </Heading>

                <FormControl size="sm" my={3}>
                  <FormLabel>
                    {t(
                      "order_validation_modal_bill_metadata_order_reference",
                      "Numéro de l'ordre de service (numéro de BDC client)",
                    )}
                    <Tooltip
                      label={t(
                        "order_validation_modal_bill_metadata_order_reference_tooltip",
                        "Numéro d’ordre de service indiqué sur votre contrat de sous-traitance",
                      )}
                    />
                  </FormLabel>
                  <Text fontSize="sm" fontWeight="normal">
                    {reference}
                  </Text>
                </FormControl>
                <FormControl size="sm" my={3}>
                  <FormLabel htmlFor="name">
                    {t(
                      "order_validation_modal_bill_metadata_invoice_number",
                      "Numéro de facture",
                    )}
                    <Tooltip
                      label={t(
                        "order_validation_modal_bill_metadata_invoice_number_tooltip",
                        "Votre numéro de facture",
                      )}
                    />
                  </FormLabel>
                  <Text fontSize="sm" fontWeight="normal">
                    {bill?.name}
                  </Text>
                </FormControl>

                <FormControl size="sm" my={3}>
                  <FormLabel>
                    {t(
                      "order_validation_modal_bill_metadata_siret",
                      "Numéro de SIRET",
                    )}
                    <Tooltip
                      label={t(
                        "order_validation_modal_bill_metadata_siret_tooltip",
                        "Numéro de SIRET qui servira à procéder au virement de la facture",
                      )}
                    />
                  </FormLabel>
                  <Text fontSize="sm" fontWeight="normal">
                    {siret}
                  </Text>
                </FormControl>

                <FormControl size="sm" my={3}>
                  <FormLabel>
                    {t(
                      "order_validation_modal_bill_metadata_total_amount_ht",
                      "Montant total HT",
                    )}
                    <Tooltip
                      label={t(
                        "order_validation_modal_bill_metadata_total_amount_ht_tooltip",
                        "Montant total correspondant à celui de l’ordre de service, rappelé sur votre contrat de sous-traitance",
                      )}
                    />
                  </FormLabel>
                  {bill?.fastCash ? (
                    <Flex flexDirection={"row"}>
                      <Text fontSize="sm" fontWeight="normal" as={"del"} mr={4}>
                        {toEuro(bill.cost || 0)}
                      </Text>
                      <Text fontSize="sm" fontWeight="normal">
                        {toEuro((bill?.cost || 0) * 0.98)}
                      </Text>
                    </Flex>
                  ) : (
                    <Text fontSize="sm" fontWeight="normal">
                      {toEuro(bill?.cost || 0)}
                    </Text>
                  )}
                </FormControl>

                <FormControl size="sm" my={3}>
                  <FormLabel htmlFor="deliveryDate">
                    Date d'échéance estimée de la facture
                    (
                    {bill?.fastCash
                      ? "FastCash: J+10"
                      : `J+${project?.paymentDelay}`}
                    )
                    <Tooltip
                      label={t(
                        "order_validation_modal_bill_metadata_delivery_date_tooltip",
                        "Date à laquelle le virement sera exécuté si le dossier est valide",
                      )}
                    />
                  </FormLabel>
                  <Text fontSize="sm" fontWeight="normal">
                    {bill?.deliveryDate &&
                      format(bill?.deliveryDate, "dd/MM/yyyy")}
                  </Text>
                </FormControl>

                <FormControl size="sm">
                  <FormLabel htmlFor="comment">
                    {t(
                      "order_validation_modal_bill_metadata_comment",
                      "Commentaire",
                    )}
                  </FormLabel>
                  <Text size="sm">{bill?.partnerComment}</Text>
                </FormControl>
              </GridItem>
            </Grid>
            {!isButtonEnabled && (
              <Alert status="warning" w={"100%"}>
                <AlertDescription>
                  {t(
                    "order_validation_modal_bill_metadata_warning",
                    "N'oubliez pas que vous devez déposer et compléter votre justificatif et votre facture avec un montant correct avant de pouvoir soumettre votre dossier de facturation.",
                  )}
                </AlertDescription>
              </Alert>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <SubmitModal
        isLoading={isLoading}
        isOpen={isOpen}
        label={t(
          "order_validation_modal_submit_bill_label",
          "Souhaitez-vous vraiment soumettre votre dossier de facturation ?",
        )}
        onClose={onClose}
        dataCy="submit-facturation"
        onPublish={onSubmit}
      />
    </>
  );
};
